import "react-quill/dist/quill.snow.css";
import "./styles.css";

import { cn } from "~/utilities/cn";

export interface QuillRenderHtmlProps {
  html: string;
  className?: string; // Optional className to pass Tailwind CSS styles
}

export default function QuillRenderHtml({
  html,
  className,
}: QuillRenderHtmlProps) {
  return (
    <div className={cn("quill-container ql-snow", className)}>
      <div
        className="ql-editor text-color"
        dangerouslySetInnerHTML={{ __html: html }}
      ></div>
    </div>
  );
}
